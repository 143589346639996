import React from 'react'
import Contact from '../components/Contact/Contact'

function ContactUs() {
  return (
    <>
    <Contact/>
    </>
  )
}

export default ContactUs