import React from "react";
import { images8 } from "../../Data/oursaints";
import DownloadCard8 from "./DownloadCard8";

function DownloadList8() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">Vadtal Dham</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images8.map((value, index) => (
          <DownloadCard8 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList8;
