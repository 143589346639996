import React from "react";
import OurSaintsCard from "../components/OurSaints/OurSaintsCard";
import OurSaintsList from "../components/OurSaints/OurSaintsList";

function OurSaints() {
  return (
    <>
      <OurSaintsList />
    </>
  );
}

export default OurSaints;
