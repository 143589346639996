import React from "react";
import KathaKirtanList from "../components/KathaKirtan/KathaKirtanList";

function KathaKirtan() {
  return (
    <>
      <KathaKirtanList />
    </>
  );
}

export default KathaKirtan;
