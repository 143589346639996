import React from "react";
import CarouselComponent from "../components/home/Carousel/CarouselComponent";
import LiveTv from "../components/Live/LiveTv";
import Live2 from "../components/Live/Live2";

function Home() {
  return (
    <>
      <CarouselComponent />
      <Live2 />
      {/* <div className="flex items-center justify-center object-cover p-8">
        <iframe
          width="660"
          height="415"
          src="https://www.youtube.com/embed/p13iQ1UunZw?si=n2SsuNkFLTkWsYZW"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div> */}
    </>
  );
}

export default Home;
