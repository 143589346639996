import React from "react";

function AboutCard() {
  const Paragraph = ({ children }) => (
    <p className={sharedClasses.paragraph}>{children}</p>
  );
  const ImageSection = ({ src, alt }) => (
    <img className={sharedClasses.image} src={src} alt={alt} />
  );

  const InfoSection = () => (
    <div className={sharedClasses.textContainer}>
      <p>
        <strong>Birth Date :</strong> 3 April 1781 (Chaitra Sud Navami, Samvat
        1837)
      </p>
      <p>
        <strong>Birth Place :</strong> Chhapaiya (present-day Uttar Pradesh,
        India)
      </p>
      <p>
        <strong>Died :</strong> 1 June 1830 (aged 49)
      </p>
      <p>
        <strong>Died Place :</strong> Gadhada (present-day Gujarat, India)
      </p>
    </div>
  );

  return (
    <div>
      <div className=" text-center mb-8 p-6">
        <div className="inline-block bg-transparent text-black shadow-2xl font-bold py-2 px-4 rounded-full">
          About Gurukulam
        </div>
      </div>
      <div className={sharedClasses.container}>
        <h1 className={sharedClasses.heading}>Lord Swaminarayan</h1>
        <div className={sharedClasses.flexContainer}>
          <ImageSection
            src="https://divyadhamgurukulam.edu.in/image/lordswaminarayan.jpg"
            alt="Lord Swaminarayan image"
          />
          <InfoSection />
        </div>
        <Paragraph>
          Lord Shri Swaminarayan was born to a samvedi Brahmin family on 9th day
          of bright half of the month Chaitra of Vikram Samvat 1837 i.e. on 3rd
          April, 1781, in a small village called Chhapaiya near Ayodhya, U.P.,
          India. His father’s name was Dharmadev and his mother’s name was
          Bhaktimata. In the tender age of 7 years only, he learned all the
          religious scriptures including Vedas from his father and became
          victorious in a conference of scholars held in Kashi. After serving
          his parents, at the age of 11 only, he left village Chhapaiya and
          proceed alone towards the forests in the north.
        </Paragraph>
        <Paragraph>
          Lord Shri Swaminarayan came to Gujarat at the age of 18 and extended
          his work of spreading true religion. He propagated Uddhav
          (Swaminarayan) doctrine popularly known as Vishishtadvait.
        </Paragraph>
        <Paragraph>
          At age 21, he established Swaminarayan Sampraday, with the blessings
          of his Guru Sadguru Ramanand Swami. He initiated more than 1500 saints
          to spread the pious Sampraday. To achieve ultimate redemption he
          taught the Swaminarayan mahamantra.
        </Paragraph>
        <Paragraph>
          To preserve the purity in the religion, he devised five basic Codes of
          Conducts for Haribhakt (Devotee) like not to steal, not to eat meat,
          not to drink, not to commit adultery, not to follow impurity and for
          Tyagi (Saints) like, Nishkam (Lust-less), Nirlobh (Greed-less),
          Nissneh (Attachment-less), Nisswad (Taste-less) and Nirman (Ego-less).
        </Paragraph>
        <Paragraph>
          He established beautiful temples at Ahmedabad, Bhuj, Vadtal, Dholera,
          Junagadh, Gadhpur for Upasana -worshipping God in all His greatness &
          glory and Bhakti - Devotion. With all these, he gave a small but huge
          Code of Conducts with only 212 verses, The Shikshapatri. And the
          nectar of spiritual discourses performed by Bhagwan Swaminarayan, The
          Vachanamrutam.
        </Paragraph>
        <Paragraph>
          Thus, in a short span of 49 years He revived Hinduism, glorified
          spiritual values and founded the Swaminarayan Faith and left for His
          Divine Abode, Akshardham. Mahatma Gandhiji has opined In India, there
          are so many religious sects, but the Swaminarayan sect is praise
          worthy, pious, pure and attractive. I have a very high regard for this
          sect.
        </Paragraph>
        <Paragraph>
          The great follower of Mahatma Gandhi and a well known thinker Shri
          Kishorlal Mashruvala has said about Lord Shri Swaminarayan With his
          divine light, he enlighted the hearts of several people, took away the
          bad habits and vices of backward people, included non-hindus in Hindu
          religion, encouraged the literature, music and arts, promoted
          non-violent Yagnas, directed the way of pure devotion and divine
          knowledge. He was the tutor of Bhagawat-Dharma and the preacher of the
          principles of Vyasji.
        </Paragraph>
      </div>
    </div>
  );
}

export default AboutCard;

const sharedClasses = {
  paragraph: "mt-4",
  container:
    "bg-card text-card-foreground p-6 rounded-lg shadow-md max-w-4xl mx-auto",
  heading: "text-3xl font-bold mb-4",
  flexContainer: "flex flex-col md:flex-row",
  image: "w-full md:w-1/3 rounded-lg mb-4 md:mb-0 md:mr-6",
  textContainer: "md:w-2/3",
};
