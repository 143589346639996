import React from "react";
import LiveTv from "../components/Live/LiveTv";
import Live2 from "../components/Live/Live2";

function Live() {
  const Paragraph = () => {
    return (
      <p className={sharedTextClasses}>
        જય સ્વામિનારાયણ, જયારે કથા-વાર્તા{" "}
        <span className={sharedSpanClasses}>Live</span> થશે ત્યારે નીચીની TV માં
        આપ નિહાળી શકો છો.
        <br />
        વધુ વિડિઓઝ, કથા <span className={sharedYouTubeClasses}>YouTube</span> પર
        જોઈ શકો.
      </p>
    );
  };
  const Paragraph2 = () => {
    return (
      <p className={sharedTextClasses}>
        If there are any issues :
        <br />
        Call: +91 96012 91601 <br /> Email: DivyadhamVelanja@gmail.com
      </p>
    );
  };
  return (
    <>
      <div className=" text-center  p-6">
        <div className="inline-block bg-transparent text-black shadow-2xl font-bold py-2 px-4 rounded-full">
          Live
        </div>
      </div>
      <div className="p-6">
        <Paragraph />
      </div>
      <Live2 />
      <div className="p-6">
        <Paragraph2 />
      </div>
    </>
  );
}

export default Live;

// Shared Tailwind CSS classes
const sharedTextClasses = "text-lg text-zinc-800 dark:text-zinc-200";
const sharedSpanClasses = "font-bold";
const sharedYouTubeClasses = "text-red-600 dark:text-red-400";
