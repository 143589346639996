import React from "react";
import SadGranthList from "../components/SadGranth/SadGranthList";

function SadGranth() {
  return (
    <>
      <SadGranthList />
    </>
  );
}

export default SadGranth;
