import React from "react";
import AboutCard from "../components/AboutUs/AboutCard";
import EduSection from "../components/AboutUs/EduSection";

function Aboutus() {
  return (
    <>
      <AboutCard />
      <EduSection/>
    </>
  );
}

export default Aboutus;
