import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import OurSaints from "./pages/OurSaints";
import KathaKirtan from "./pages/KathaKirtan";
import Live from "./pages/Live";
import SadGranth from "./pages/SadGranth";
import Gallery from "./pages/Gallery";
import Download from "./pages/Download";
import Blog from "./pages/Blog";
import News from "./pages/News";
import Donation from "./pages/Donation";
import ContactUs from "./pages/ContactUs";
import SocialMedia from "./components/SocialMedia/SocialMedia";
import OurSaintsDetalis from "./pages/OurSaintsDetalis";

function App() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "rgb(188,160,154)",
        background:
          "linear-gradient(90deg, rgba(188,160,154,1) 0%, rgba(250,225,204,0.9641106442577031) 4%, rgba(250,225,204,0.9557072829131653) 27%, rgba(250,225,204,0.9501050420168067) 41%, rgba(250,225,204,0.9613095238095238) 53%, rgba(250,225,204,0.9697128851540616) 68%, rgba(250,225,204,1) 84%, rgba(250,225,204,0.9445028011204482) 96%, rgba(188,160,154,1) 100%)",
      }}
      // style={{
      //   height: "100%",
      //   width: "100%",
      //   background: "rgb(133, 158, 175)",
      //   background:
      //     "linear-gradient(180deg, rgba(133,158,175,1) 15%, rgba(210,200,188,1) 53%, rgba(255,197,107,1) 95%)",
      // }}

      // style={{
      //   height: "100%",
      //   width: "100%",
      //   background: "rgb(186,241,209)",
      //   background:
      //     "linear-gradient(133deg, rgba(186,241,209,1) 2%, rgba(240,236,155,1) 100%)",
      // }}

      // style={{
      //   height: "100%",
      //   width: "100%",
      //   background: "rgb(255,137,49)",
      //   background:
      //     "linear-gradient(172deg, rgba(255,137,49,1) 0%, rgba(255,245,228,1) 26%, rgba(193,216,195,1) 76%, rgba(106,156,137,1) 100%)",
      // }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="AboutUs" element={<Aboutus />} />
            <Route path="OurSaints" element={<OurSaints />} />
            <Route path="KathaKirtan" element={<KathaKirtan />} />
            <Route path="Live" element={<Live />} />
            <Route path="SadGranth" element={<SadGranth />} />
            <Route path="Gallery" element={<Gallery />} />
            <Route path="Download" element={<Download />} />
            <Route path="Blog" element={<Blog />} />
            <Route path="News" element={<News />} />
            <Route path="Donation" element={<Donation />} />
            <Route path="ContactUs" element={<ContactUs />} />
            <Route path="/:name" element={<OurSaintsDetalis />} />
          </Route>
        </Routes>
        {/* <SocialMedia/> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
