import React from "react";
import { saints } from "../../Data/oursaints";
import OurSaintsCard from "./OurSaintsCard";

function OurSaintsList() {
  return (
    <div className="p-8">
      <div className=" text-center mb-8">
        <div className="inline-block bg-transparent text-black shadow-2xl font-bold py-2 px-4 rounded-full">
          Our Saints
        </div>
      </div>
      {saints.map((value, index) => {
        return <OurSaintsCard key={index} saint={value} />;
      })}
    </div>
  );
}

export default OurSaintsList;
