import React from "react";

function Live2() {
  return (
    <>
      <div className="flex justify-center items-center bg-[var(--background)] p-[5%]">
        <div className="w-full max-w-4xl">
          <div className={videoContainerClasses}>
            <iframe
              className={iframeClasses}
              src="https://www.youtube.com/embed/live_stream?channel=UC4OoID36kJbK-W2NXmPHiVQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="flex items-center justify-center">
            <a
              href="https://youtube.com/@divyadhamsurat?si=nIA6TNgjrsL_jrln"
              target="_blank"
            >
              <button className="mt-6  py-2 px-4 rounded-full bg-transparent text-black shadow-2xl font-bold ">
                All Recorded Katha
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Live2;

const videoContainerClasses = "relative pb-[56.25%] h-0 overflow-hidden";
const iframeClasses = "absolute top-0 left-0 w-full h-full";
