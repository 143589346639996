import { Carousel } from "flowbite-react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper/modules";

function CarouselComponent() {
  return (
    // <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
    //   <Carousel leftControl="left" rightControl="right">
    //     <img src="/images/front2.jpg" alt="..." />
    //     <img src="/images/front3.jpg" alt="..." />
    //     <img src="/images/front4.jpg" alt="..." />
    //     <img src="/images/front5.jpg" alt="..." />
    //   </Carousel>
    // </div>
    <Swiper
      pagination={true}
      loop={true}
      autoplay={{
        delay: "2500",
        disableOnInteraction: false,
      }}
      modules={[Pagination, Autoplay]}
      // className="w-full h-full"
    >
      <SwiperSlide className="" style={{ width: "100vw" }}>
        <div className="w-full">
          <img
            src="/images/front2.jpg"
            alt=""
            className="w-full h-auto object-contain"
          />
        </div>
      </SwiperSlide>
      <SwiperSlide className="" style={{ width: "100vw" }}>
        <div className="w-full">
          <img
            src="/images/front3.jpg"
            alt=""
            className="w-full h-auto object-contain"
          />
        </div>
      </SwiperSlide>
      <SwiperSlide className="" style={{ width: "100vw" }}>
        <div className="w-full">
          <img
            src="/images/front4.jpg"
            alt=""
            className="w-full h-auto object-contain"
          />
        </div>
      </SwiperSlide>
      <SwiperSlide className="" style={{ width: "100vw" }}>
        <div className="w-full">
          <img
            src="/images/front5.jpg"
            alt=""
            className="w-full h-auto object-contain"
          />
        </div>
      </SwiperSlide>
      {/* <SwiperSlide className="!w-full" style={{ width: "100%" }}>
          <div className="w-full">
            <img src="/images/front3.jpg" alt="..." className="w-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="!w-full" style={{ width: "100%" }}>
          <div className="w-full">
            <img src="/images/front4.jpg" alt="..." className="w-full" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="!w-full" style={{ width: "100%" }}>
          <div className="w-full">
            <img src="/images/front5.jpg" alt="..." className="w-full" />
          </div>
        </SwiperSlide> */}
    </Swiper>
  );
}

export default CarouselComponent;
