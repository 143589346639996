import React from "react";

function DonationCard() {
  const Paragraphs = () => (
    <>
      <p className={marginBottomClass}>
        આપણા પૌરાણિક શાસ્ત્રો જેવા કે, ગરુડપુરણ, આગ્નિ પુરાણ તથા હેમાંદ્રિદાનખંદ
        વગેરેમાં સદવિદ્યા પ્રવર્તનનો અદકેરો મહિમા લખાયો છે. સદવિદ્યા ની
        પ્રવૃત્તિમાં દાન આપનારને અંધને ચક્ષુદાન કરવા જેટલું પુણ્ય મળે છે.
        વિધાર્થીઓને ભોજન, વસ્ત્ર,ભિક્ષા, ઔષધ અને પુસ્તકો આપનારની સર્વ મનોકામનાઓ
        પૂર્ણ થાય છે એમાં સંશય નથી જે મનુષ્ય વિધાર્થીને દરરોજ દિપક, વસ્ત્ર અને
        ભોજન આપે છે એને યજ્ઞનું ફળ પ્રાપ્ત થાય છે.
      </p>
      <p className={marginBottomClass}>
        આ વિદ્યા દાનમાં આપ સહભાગી બની બીજાને પણ સહભાગી બનાવતાં રહો.
      </p>
    </>
  );

  const Note = () => (
    <div className={`${borderClass} ${paddingClass} ${marginBottomClass}`}>
      <p className="text-center font-semibold">
        Note: All donations made by Indian Resident are 50% tax free under the
        IT section 80G(5) in India.
      </p>
      <p className="text-center">
        તા.ક:- આ સંસ્થાને આપેલ દાન આપનારને આવકવેરા અધિનિયમની કલમ ૮૦ જી (૫) હેતુ
        હેઠળ ૫૦% ટેક્ષ મુક્તિ મળે છે.
      </p>
    </div>
  );

  const WireTransfer = () => (
    <div className="mb-8">
      <h2 className="font-bold mb-2">
        Donation through Wire transfer (Bank transfer):
      </h2>
      <h2 className="font-bold mb-2">
        વાયર ટ્રાન્‍સફર એકાઉન્‍ટ દ્વારા દાન (બેંક ટ્રાન્સફર)
      </h2>
      <table className="w-full border-collapse border border-[var(--border)]">
        <tbody>
          {[
            "Name of the Bank",
            "Branch",
            "Branch Address",
            "RTGS / NEFT / IFCS code",
            "Account No",
            "Account Name (Beneficiary)",
            "Address (Beneficiary)",
          ].map((item, index) => (
            <tr key={index} className={borderClass}>
              <td className={paddingClass}>{item}</td>
              <td className={paddingClass}>
                * We are Updating this Detail Very Shortly.
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const ChequeOrDraft = () => (
    <div className="mb-8">
      <h2 className="font-bold mb-2">Donation by Cheque or Draft:</h2>
      <h2 className="font-bold mb-2">ચેક અથવા ડ્રાફ્ટ દ્વારા દાન</h2>
      <p className={marginBottomClass}>
        Please write cheque or draft in favour of -<br />
        "Shree Swaminarayan Gurukulam", Payable at Surat and send it to
        <br />
        Shree Swaminarayan Gurukulam DivyaDham, Sayan Kathor Road, Velanja,
        Surat - 395004 Gujarat, India.
      </p>
    </div>
  );

  const EmailNote = () => (
    <div className="mb-8">
      <h2 className="font-bold mb-2">Note:(નોંધ:)</h2>
      <p className={marginBottomClass}>
        Please email GurukulamVelanja@gmail.com once you have made the donation,
        tell us what your Full Name, Village, Current Address, City, Country,
        Mobile Number and the Purpose of your Donation.
      </p>
      <p className={marginBottomClass}>
        દાન કર્યા બાદ તમારું નામ, ગામ નું નામ, સરનામું, શહેર, ફોન નંબર ની માહિતી
        નો ઈમૈલ GurukulamVelanja@gmail.com પર કરવા વિનંતી.
      </p>
    </div>
  );

  const Enquiry = () => (
    <div>
      <h2 className="font-bold mb-2">Donations Enquiry:</h2>
      <p>
        Email: GurukulamVelanja@gmail.com
        <br />
        Tel: +91 96012 91601
        <br />
        Mobile: +91 96012 91601
      </p>
    </div>
  );

  return (
    <>
      <div className=" text-center mb-2 p-6">
        <div className="inline-block bg-transparent text-black shadow-2xl font-bold py-2 px-4 rounded-full">
          Donation
        </div>
      </div>
      <div className="bg-[var(--background)] text-[var(--foreground)] p-4 max-w-4xl mx-auto">
        <Paragraphs />
        <Note />
        <WireTransfer />
        <ChequeOrDraft />
        <EmailNote />
        <Enquiry />
      </div>
    </>
  );
}

export default DonationCard;

// Shared Tailwind class constants
const borderClass = "border border-[var(--border)]";
const paddingClass = "p-2";
const marginBottomClass = "mb-4";
