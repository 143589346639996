import React from "react";
import KathaKirtanCard from "./KathaKirtanCard";
import { cdData } from "../../Data/oursaints";

function KathaKirtanList() {
  return (
    <div className="bg-[url('https://www.toptal.com/designers/subtlepatterns/patterns/sandpaper.png')] p-8">
      <div className=" text-center mb-8">
        <div className="inline-block bg-transparent text-black shadow-2xl font-bold py-2 px-4 rounded-full">
          Katha - Kirtan
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 px-[10%]">
        {cdData.map((value, index) => (
          <KathaKirtanCard key={index} cd={value} />
        ))}
      </div>
    </div>
  );
}

export default KathaKirtanList;
