import React from "react";

function DownloadCard7({image}) {
  return (
    <div className="flex flex-col items-center">
      <img className="border-4 " src={image.src} />
      <a
        href={image.href}
        download={image.logoname}
        className="mt-2 text-red-700"
      >
        Download
      </a>
    </div>
  );
}

export default DownloadCard7;
