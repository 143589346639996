import React from "react";
import { images9 } from "../../Data/oursaints";
import DownloadCard9 from "./DownloadCard9";

function DownloadList9() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">Ladila Ghanshyam Maharaj</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images9.map((value, index) => (
          <DownloadCard9 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList9;
