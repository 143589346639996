import React from "react";
import { images3 } from "../../Data/oursaints";
import DownloadCard3 from "./DownloadCard3";

function DownloadList3() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">Saints & Aachrya</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images3.map((value, index) => (
          <DownloadCard3 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList3;
