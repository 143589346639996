import { Footer } from "flowbite-react";
import React from "react";
import { Link } from "react-router-dom";

function FooterComponent() {
  return (
    <Footer container className="bg-transparent shadow-xl">
      <div className="w-full text-center">
        <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
          {/* <Footer.Brand
            href="https://flowbite.com"
            src="https://flowbite.com/docs/images/logo.svg"
            alt="Flowbite Logo"
            name="Flowbite"
          /> */}
          <Footer.LinkGroup className="flex gap-2">
            <Link to="" className="text-black hover:underline">
              Home |
            </Link>
            <Link to="" className="text-black hover:underline">
              About us |
            </Link>
            <Link to="" className="text-black hover:underline">
              Our-Saint |
            </Link>
            <Link to="" className="text-black hover:underline">
              Katha-Kirtan |
            </Link>
            <Link to="" className="text-black hover:underline">
              SadGranth |
            </Link>
            <Link to="" className="text-black hover:underline">
              Gallery |
            </Link>
            <Link to="" className="text-black hover:underline">
              Download |
            </Link>
            <Link to="" className="text-black hover:underline">
              Blog |
            </Link>
            <Link to="" className="text-black hover:underline">
              News |
            </Link>
            <Link to="" className="text-black hover:underline">
              Donation |
            </Link>
            <Link to="" className="text-black hover:underline">
              Contact Us |
            </Link>
            <Link to="" className="text-black hover:underline">
              Privacy Policy |
            </Link>
            <Link to="" className="text-black hover:underline">
              Terms and Conditions |
            </Link>
            <Link to="" className="text-black hover:underline">
              Cookies Policy |
            </Link>
            <Link to="" className="text-black hover:underline">
              Sitemap
            </Link>
          </Footer.LinkGroup>
        </div>
        <Footer.Divider />
        {/* <hr /> */}
        <Footer.Copyright
          by="Copyright . All Rights Reserved by Shree Swaminarayan Gurukulam Divyadham, Velanja"
          year={2024}
          className="text-black hover:underline"
        />
        {/* <Footer.Copyright
          by="Phone: +91 96012 91601, Email: GurukulamVelanja@gmail.com"
          className="text-[#CBAFA6] hover:text-black"
        /> */}
        {/* <Footer.Copyright
          by="Developed by : Himanshu Bhagat | Siddharth Bhagat"
          className="text-[#CBAFA6] hover:text-black"
        />
        <Footer.Copyright
          by="Inspiration by : Shastri Swami Harikrishnadasji"
          className="text-[#CBAFA6] hover:text-black"
        /> */}
        <p className="text-black hover:underline">
          Phone: +91 96012 91601, Email: GurukulamVelanja@gmail.com
        </p>
        <p className="text-black hover:underline">
          Developed by : Himanshu Bhagat | Siddharth Bhagat
        </p>
        <p className="text-black hover:underline">
          Inspiration by : Shastri Swami Harikrishnadasji
        </p>
      </div>
    </Footer>
  );
}

export default FooterComponent;
