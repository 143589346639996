import React from "react";

function EduSection() {
  return (
    <div className="bg-[var(--background)] text-[var(--foreground)] p-4 md:p-8 lg:p-12">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          Shree Swaminarayan Gurukulam Divyadham
        </h1>
        <p className="text-center text-lg md:text-xl lg:text-2xl mb-8">
          संस्कृत विहीनः पशुवद् विद्याच्च | प्रकटीनाम् साक्षात् गुरु राजसूय
          नक्षत्र || (शिक्षा. ३२१)
        </p>
        <div className="flex flex-col gap-6 ">
          <p className="text-xl tracking-wider leading-relaxed ">
            શ્રી સ્વામિનારાયણ ભગવાને શિક્ષાપત્રીમાં લખ્યું છે : વિધાર્થી
            ભણાવ્યાની શાળા કરાવીને પછી તેમાં એક વિદ્વાન બ્રાહ્મણને રાખીને
            પૃથ્વીને વિષે સદવીધ્યાની પ્રવૃત્તિ કરાવવી; કેમ જે, વિદ્યાદાને કરીને
            મોટું પુણ્ય છે ||૧૩૨||
          </p>
          <p className="text-xl tracking-wider leading-relaxed">
            આજના સમયની માંગ અને વ્યક્તિના વૈચારિક વૈમનસ્ય, આધુનિક ટેકનોલોજીના
            યુગમાં સંસ્કાર વિહોણા બનતા જતા ભાવી પેઢીના જીવનની માત-પિતાની ચિંતા
            અને ઘર ઘર પ્રત્યેની અનેક સમસ્યાઓ ઉપર ખુબ જ વિચાર-વિમર્શ કરતા જણાયું
            કે, ભાવી પેઢીને-આપના સંતાનોને અધ્યતન શિક્ષણ મેળવવા ઉપરાંત
            કૌટુંબિકસેવા, સામાજીકસેવા, દેશસેવા અને સંસ્કાર સભર આધ્યાત્મિકતાના
            પાઠો શીખી આપણી પૂનિત સંસ્કૃતિનું જ્ઞાન થાય. અને આપની ભાવી પેઢી
            પોતાનો સર્વાંગી વિકાસ કરી માતા-પિતા અને ગુરુજનો ગૌરવ લઇ શકે એવું
            જીવન જીવતા થાય એવી સર્વજનહિત પ્રવૃતિને પ્રભુ પ્રસંનાર્થે કરવી.
          </p>
          <p className="text-xl tracking-wider leading-relaxed">
            પ્રત્યેક માબાપને એવી અભિલાષા હોય છે કે પોતાનું બાળક અભ્યાસ
            કુટુંબપરાયણ, સંસ્કારી અને આદર્શ નાગરિક બની સમયના સથવારે ચાલતો રહે
            અને પોતાનું આત્મ્શ્રેય સાધે... આપના સંતાનો પ્રત્યેના સ્વપ્નાને સાકાર
            કરતુ અને સંપૂર્ણ જીવનલક્ષી સંસ્કાર સભર શિક્ષણ સાથે સર્વાંગી વિકાસ
            કરતું અદ્યતન સુવિધાઓથી સુસજ્જ વિદ્યાસંકુલ એટલે ‘શ્રી સ્વામિનારાયણ
            ગુરુકુલમ દીવ્ય્ધામ’ વેલંજા-સુરત વિસ્તારમાં આપના સાથ સહકારથી નવ
            નિર્મિત થનાર છે
          </p>
          <p className="text-xl tracking-wider leading-relaxed">
            વ્હાલા સજ્જનો ! ઇષ્ટદેવ શ્રી સ્વામિનારાયણ ભગવાનની અસીમ કૃપાથી તેમજ
            વડતાલપીઠાધીપતિ પ.પૂ.ધ.ધૂ. ૧૦૦૮ શ્રી રાકેશપ્રસાદજી મહારાજના રૂડા
            આશીર્વાદથી અને પ.પૂ.સદ. શ્રી હરિપ્રિયદાસજી સ્વામી ની પ્રેરણાથી ‘શ્રી
            સ્વામિનારાયણ ગુરુકુલમ દીવ્ય્ધામ’ ના નામથી અદ્યતન સુવિધાવાળું ગુજરાતી
            માધ્યમમાં શિક્ષણ અપાતું (ભવિષ્યમાં અંગ્રીજી માધ્યમના પ્લાનિંગ
            સાથેનું) વિદ્યાસંકુલનું નિર્માણ કરવાનું નિર્ધાર્યું છે.
          </p>
          <p className="text-xl tracking-wider leading-relaxed">
            સમય હાથમાંથી સરકી જાય છે. શાંત અને સ્વસ્થ ચિત્તે વિચારીએ તો સમય સાદ
            દેતો આપણને પોકારી રહ્યો છે કે... સમાજ પાસેથી મેળવેલું સમાજ માટે
            સદવ્યય કરીએ. ‘સત્ય વાતને સ્વીકારી તક ઝડપી લઈએ... આપે આપના ઉદારહાથે
            સુસંસ્કારના કેન્દ્ર તરફ દાનના સ્ત્રોત વહાવ્યા છે ને સવેળાએ ચેતી જીવન
            સાર્થક કર્યું છે તો વિશેષ સર્વજનહિત પ્રવુતિમાં પણ સહભાગી બનતા રહો
            અને બીજાના પુણ્યમાં સહભાગી બનાવતા રહો એવી અપેક્ષા સહ.....
          </p>
        </div>
        <div>
          <img
            src="https://divyadhamgurukulam.edu.in/image/gurukulam_future.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default EduSection;
