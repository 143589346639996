import React from "react";

function ResponsiveImage() {
  const IMAGE_CLASSES = "max-w-full h-auto rounded-md object-cover  ";

  return (
    <div className="flex items-center justify-center">
      <img
        aria-hidden={true}
        alt="responsive-image"
        src="/images/05_new.png"
        className={`${IMAGE_CLASSES} md:max-w-md lg:max-w-lg xl:max-w-xl `}
      />
    </div>
  );
}

export default ResponsiveImage;
