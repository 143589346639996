import React, { useState } from "react";

import DownloadCard from "../components/Download/DownloadCard";
import DownloadList from "../components/Download/DownloadList";
import DownloadList2 from "../components/Download/DownloadList2";
import DownloadList3 from "../components/Download/DownloadList3";
import DownloadList4 from "../components/Download/DownloadList4";
import DownloadList5 from "../components/Download/DownloadList5";
import DownloadList6 from "../components/Download/DownloadList6";
import DownloadList7 from "../components/Download/DownloadList7";
import DownloadList8 from "../components/Download/DownloadList8";
import DownloadList9 from "../components/Download/DownloadList9";

function Download() {
  return (
    <>
      {/* <div className="p-4 px-[10%]">
        <h2 className="text-2xl font-bold mb-4">Lila Charitra</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {images.map((value) => {
            return <DownloadCard image={value} />;
          })}
        </div>
      </div> */}
      <div className=" text-center mb-2 p-6">
        <div className="inline-block bg-transparent text-black shadow-2xl text-xl font-bold py-2 px-4 rounded-full">
          મોટી સાઈઝ ના પ્રિન્ટ કરાવી શકાય એવા વોલપેપર
        </div>
      </div>
      <DownloadList />
      <DownloadList2 />
      <DownloadList3 />
      <DownloadList4 />
      <DownloadList5 />
      <DownloadList6 />
      <DownloadList7 />
      <DownloadList8 />
      <DownloadList9 />
    </>
  );
}

export default Download;
