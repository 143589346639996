import React from "react";

function KathaKirtanCard({ cd }) {
  return (
    <div className="text-center ">
      <img className="rounded-full mx-auto" src={cd.src} />
      <p className="mt-1">{cd.title}</p>
    </div>
  );
}

export default KathaKirtanCard;
