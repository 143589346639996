import React from "react";

function DownloadCard({ image }) {
  return (
    <div className="flex flex-col items-center">
      <img className="border-4 " src={image.src} />
      <a
        href={image.href}
        download={image.logoname}
        className="mt-2 text-red-700"
      >
        Download
      </a>
    </div>
  );
}

export default DownloadCard;

// Shared Tailwind CSS classes
// const sharedClasses = {
//   container: "flex flex-col items-center",
//   image: "border-4 border-red-700",
//   link: "mt-2 text-red-700",
// };
