import React from "react";
import { images5 } from "../../Data/oursaints";
import DownloadCard5 from "./DownloadCard5";

function DownloadList5() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">Velanja Ghanshyam</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images5.map((value, index) => (
          <DownloadCard5 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList5;
