import React from "react";
import { images4 } from "../../Data/oursaints";
import DownloadCard4 from "./DownloadCard4";

function DownloadList4() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">Guru Parmpara</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images4.map((value, index) => (
          <DownloadCard4 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList4;
