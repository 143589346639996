import React from "react";
import { useNavigate } from "react-router-dom";

function OurSaintsCard({ saint }) {
  // const navigate = useNavigate();

  // function GoToDetail() {
  //   navigate(`/${saint.name}`, { state: saint });
  // }
  return (
    <div className={cardClass}>
      <div className="flex flex-col md:flex-row">
        <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
          <img
            src={saint.img}
            alt="Portrait of Aksharmurti Sadguru Gunatitanand Swami"
            className={imageClass}
          />
        </div>
        <div>
          <h2 className={titleClass}>{saint.name}</h2>
          <p className={infoClass}>
            <strong>Original Name:</strong>
            {saint.originalname}
          </p>
          <p className={infoClass}>
            <strong>Birth Date:</strong> {saint.Birthdate}
          </p>
          <p className={infoClass}>
            <strong>Birth Place:</strong> {saint.Birthplace}
          </p>
          <p className={infoClass}>
            <strong>Died:</strong> {saint.Died}
          </p>
          <p className={infoClass}>
            <strong>Died Place:</strong> {saint.Diedplace}
          </p>
          <p className={justifyClass}>{saint.desc}</p>
          <p className={justifyClass}>{saint.desc2}</p>
          <p className={justifyClass}>{saint.desc3}</p>
          <p className={justifyClass}>{saint.desc4}</p>
          <p className={justifyClass}>{saint.desc5}</p>
          <p className={justifyClass}>{saint.desc6}</p>
          {/* <a  className={linkClass} onClick={GoToDetail}>
            Read More
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default OurSaintsCard;

const cardClass =
  "bg-card text-card-foreground p-6 rounded-lg shadow-md max-w-4xl mx-auto my-8";
const imageClass = "rounded-lg shadow-md";
const titleClass = "text-2xl font-bold mb-2";
const infoClass = "mb-1";
const justifyClass = "mt-4 text-justify";
const linkClass = "text-primary hover:underline mt-4 inline-block";
