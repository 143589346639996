import React from "react";
import SadGranthCard from "./SadGranthCard";
import { books } from "../../Data/oursaints";

function SadGranthList() {
  return (
    <div className="p-4 px-[20%]">
      <div className=" text-center mb-8">
        <div className="inline-block bg-transparent text-black shadow-2xl font-bold py-2 px-4 rounded-full">
          Sad Granth
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {books.map((value, index) => (
          <SadGranthCard key={index} book={value} />
        ))}
      </div>
    </div>
  );
}

export default SadGranthList;
