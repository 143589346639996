import React from "react";

function InfowDetalis({ data }) {
  return (
    <div className={cardClass}>
      <div className="flex flex-col md:flex-row">
        <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
          <img
            src={data.img}
            alt="Portrait of Aksharmurti Sadguru Gunatitanand Swami"
            className={imageClass}
          />
        </div>
        <div>
          <h2 className={titleClass}>{data.name}</h2>
          <p className={infoClass}>
            <strong>Original Name:</strong>
            {data.originalname}
          </p>
          <p className={infoClass}>
            <strong>Birth Date:</strong> {data.Birthdate}
          </p>
          <p className={infoClass}>
            <strong>Birth Place:</strong> {data.Birthplace}
          </p>
          <p className={infoClass}>
            <strong>Died:</strong> {data.Died}
          </p>
          <p className={infoClass}>
            <strong>Died Place:</strong> {data.Diedplace}
          </p>
          <p className={justifyClass}>{data.desc}</p>
          {/* <a href="#" className={linkClass} onClick={GoToDetail}>
            Read More
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default InfowDetalis;

const cardClass =
  "bg-card text-card-foreground p-6 rounded-lg shadow-md max-w-4xl mx-auto my-8";
const imageClass = "rounded-lg shadow-md";
const titleClass = "text-2xl font-bold mb-2";
const infoClass = "mb-1";
const justifyClass = "mt-4 text-justify";
const linkClass = "text-primary hover:underline mt-4 inline-block";
