import React from "react";
import { images6 } from "../../Data/oursaints";
import DownloadCard6 from "./DownloadCard6";

function DownloadList6() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">Rajadhiraj</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images6.map((value, index) => (
          <DownloadCard6 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList6;
