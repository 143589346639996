import React from "react";

function Contact() {
  return (
    <>
      <div className=" text-center mb-8 p-6">
        <div className="inline-block bg-transparent text-black shadow-2xl font-bold py-2 px-4 rounded-full">
        Contact
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between  p-8">
        <div className="md:w-1/2 mb-8 md:mb-0 p-[5%]">
          <h2 className="text-lg font-semibold mb-2">Address :-</h2>
          <p className={sharedClasses.mb2}>
            Shree Swaminarayan Gurukulam, Divyadham,
            <br />
            Sayan Kathor Road, Velanja, Surat - 394105,
            <br />
            Gujarat, India.
          </p>
          <p className={sharedClasses.mb2}>
            <strong>Phone</strong>{" "}
            <a href="tel:+919601291601" className={sharedClasses.primaryText}>
              +91 96012 91601
            </a>
            <br />
            <a href="tel:+919601291601" className={sharedClasses.primaryText}>
              +91 96012 91601
            </a>
          </p>
          <p className={sharedClasses.mb2}>
            <strong>Email</strong>{" "}
            <a
              href="mailto:GurukulamVelanja@gmail.com"
              className={sharedClasses.primaryText}
            >
              GurukulamVelanja@gmail.com
            </a>
          </p>
        </div>
        <div className="md:w-1/2">
          <h2 className="text-lg font-semibold mb-4">Send us a message</h2>
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="sr-only">
                Full name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Full name"
                className={sharedClasses.input}
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="name@example.com"
                className={sharedClasses.input}
              />
            </div>
            <div>
              <label htmlFor="phone" className="sr-only">
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="+910000000000"
                className={sharedClasses.input}
              />
            </div>
            <div>
              <label htmlFor="subject" className="sr-only">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                className={sharedClasses.input}
              />
            </div>
            <div>
              <label htmlFor="message" className="sr-only">
                Your Message...
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Your Message..."
                className={`${sharedClasses.input} h-24`}
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="bg-[#49263d] text-[#FD8F8F] hover:text-white p-2 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <iframe
        width="100%"
        height="400"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        id="gmap_canvas"
        src="https://www.google.com/maps/embed/v1/place?q=Shree+Swaminarayan+Divyadham+Gurukul&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embedmaps.com/google-maps-authorization/script.js?id=c9fd5d38a1c72e1c770f88f8b2e47043337b49a0"
      ></script>
    </>
  );
}

export default Contact;
// Shared Tailwind class strings
const sharedClasses = {
  input: "w-full p-2 border border-input rounded",
  mb2: "mb-2",
  primaryText: "text-primary",
};

// const ContactUs = () => (
//   <div className="flex flex-col md:flex-row justify-between bg-[url('https://placehold.co/1024x512')] p-8">
//     <AddressSection />
//     <FormSection />
//   </div>
// );
