import React from "react";

function SadGranthCard({ book }) {
  return (
    <div className="flex flex-col items-center">
      <img className="border-2 border-black " src={book.src} />
      <a href="#" className="mt-2 text-red-700">
        Download
      </a>
    </div>
  );
}

export default SadGranthCard;
