import React from "react";
import { images2 } from "../../Data/oursaints";
import DownloadCard2 from "./DownloadCard2";

function DownloadList2() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">
        જન્મ્યા કૌશલ દેશ વેશ બટુનો, લઇ તીર્થ માહી ફર્યા, રામાનંદ મળ્યા સ્વધર્મ
        ચલવ્યો યજ્ઞાદી મોટા કર્યા.
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images2.map((value, index) => (
          <DownloadCard2 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList2;
