import React from "react";
import { images7 } from "../../Data/oursaints";
import DownloadCard7 from "./DownloadCard7";

function DownloadList7() {
  return (
    <div className="p-4 px-[10%]">
      <h2 className="text-2xl font-bold mb-4">Rampura Mandir</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {images7.map((value, index) => (
          <DownloadCard7 key={index} image={value} />
        ))}
      </div>
    </div>
  );
}

export default DownloadList7;
