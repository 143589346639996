import React from "react";
import { useLocation } from "react-router-dom";
import InfowDetalis from "../components/OurSaintsDetalis/InfowDetalis";

function OurSaintsDetalis() {
  const obj = useLocation();
  const data = obj.state;
  return (
    <>
      <InfowDetalis data={data} />
    </>
  );
}

export default OurSaintsDetalis;
