import React from "react";
import DonationCard from "../components/Donation/DonationCard";

function Donation() {
  return (
    <>
      <DonationCard />
    </>
  );
}

export default Donation;
