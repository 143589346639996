import { Button, Navbar } from "flowbite-react";
import React from "react";
import { Link } from "react-router-dom";

function NavbarComponent() {
  return (
    <Navbar fluid className="bg-transparent shadow-2xl p-4">
      <Navbar.Brand href="https://flowbite-react.com">
        {/* <img
          src="/images/05_new.png"
          className="mr-3 h-6 sm:h-9"
          alt="Flowbite React Logo"
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          Flowbite React
        </span> */}
      </Navbar.Brand>
      <div className="flex md:order-2">
        {/* <Button>Get started</Button> */}
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Link to="" className="text-black hover:underline">
          Home
        </Link>
        <Link to="AboutUs" className="text-black hover:underline">
          About us
        </Link>
        <Link to="OurSaints" className="text-black hover:underline">
          Our-Saints
        </Link>
        <Link to="KathaKirtan" className="text-black hover:underline">
          Katha-Kirtan
        </Link>
        <Link to="Live" className="text-black hover:underline">
          Live
        </Link>
        <Link to="SadGranth" className="text-black hover:underline">
          SadGranth
        </Link>
        <Link to="Gallery" className="text-black hover:underline">
          Gallery
        </Link>
        <Link to="Download" className="text-black hover:underline">
          Download
        </Link>
        <Link to="Blog" className="text-black hover:underline">
          Blog
        </Link>
        <Link to="News" className="text-black hover:underline">
          News
        </Link>
        <Link to="Donation" className="text-black hover:underline">
          Donation
        </Link>
        <Link to="ContactUs" className="text-black hover:underline">
          Contact Us
        </Link>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;
